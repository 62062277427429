<template>
  <div />
</template>

<script>
export default {
  data () {
    return {
      numberReqLoading: 0,
      loader: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.stickySpinner)
    window.addEventListener('resize', this.stickySpinner)
  },
  unmounted () {
    window.removeEventListener('scroll', this.stickySpinner)
    window.removeEventListener('resize', this.stickySpinner)
  },
  methods: {
    showLoading (containerId) {
      if (!this.numberReqLoading) {
        this.loader = this.$loading.show({
          container: document.getElementById(containerId)
        })
      }

      this.numberReqLoading++

      this.stickySpinner()
    },
    hideLoading () {
      this.numberReqLoading--

      if (!this.numberReqLoading && this.loader) {
        this.loader.hide()
        this.loader = null
      }
    },
    stickySpinner () {
      const spinner = document.querySelector('.vld-container .vld-overlay .vld-icon')

      if (spinner) {
        spinner.style.transform = 'translateY(' + window.scrollY + 'px)'
      }
    }
  }
}
</script>

<style lang="scss">
#app-main {
  .vld-container .vld-overlay .vld-icon {
    position: absolute; /* position: sticky */
    top: calc(50vh - 100px);
  }
}
</style>

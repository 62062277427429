<template>
  <div id="app-main">
    <main role="main" class="wrapper">
      <nav id="sidebar">
      <ul class="list-unstyled components list-group">
        <li class="li-mobile-width">
          <router-link :to="{ name: 'Dashboard' }" active-class="active" class="list-group-item list-group-item-action">
            <img class="img-fluid navbar-icon" src="@/assets/img/view_grid-white-24px.svg">
          </router-link>
        </li>
        <li class="li-mobile-midwidth">
          <router-link :to="{ name: 'Search' }" active-class="active" class="list-group-item list-group-item-action">
            <img class="img-fluid navbar-icon" src="@/assets/img/search-white-24px.svg">
          </router-link>
        </li>
      </ul>
      </nav>
      <router-view class="tab-content" />
    </main>
  </div>
</template>

<style lang="scss">
#app-main {
  .row {
    width: auto;
  }
}
</style>
